import $ from 'jquery'
//
// import "scrollmagic";
//
window.ScrollMagic = require('scrollmagic')

var controller = new ScrollMagic.Controller()
var itemsScene, itemsTween, itemsTween2, itemsTween3, itemsTween4

$(function () { // wait for document ready
	// init
	controller = new ScrollMagic.Controller({
		globalSceneOptions: {
			triggerHook: 'onEnter'
		}
	})

	// if (window.matchMedia("(min-width: 1025px)").matches) {
	// 	var containerScene = new ScrollMagic.Scene({triggerElement: "#nav-two", duration: "400%"})
	// 		.setPin("#dots-two")
	// 		// .addIndicators({name: "pin container"}) // add indicators (requires plugin)
	// 		.addTo(controller);
	// } else {
	// 	var containerScene = new ScrollMagic.Scene({triggerElement: "#nav-two", duration: "200%"})
	// 		.setPin("#dots-two")
	// 		// .addIndicators({name: "pin container"}) // add indicators (requires plugin)
	// 		.addTo(controller);
	// }

	// itemsTween = new TimelineMax()
	// 	.add([
	// 		TweenMax.fromTo('#magic-scroll-1', 1, { top: '30%' }, { top: '0%', ease: Power3.easeOut })
	// 	])

	// if (window.matchMedia('(min-width: 1025px)').matches) {
	// 	itemsScene = new ScrollMagic.Scene({ triggerElement: '#dots-two', duration: '200%' })
	// 		.setTween(itemsTween)
	// 	// .addIndicators({name: "items"}) // add indicators (requires plugin)
	// 		.addTo(controller)
	// } else {
	// 	itemsScene = new ScrollMagic.Scene({ triggerElement: '#dots-two', duration: '200%' })
	// 		.setTween(itemsTween)
	// 	// .addIndicators({name: "items"}) // add indicators (requires plugin)
	// 		.addTo(controller)
	// }

	itemsTween2 = new TimelineMax()
		.add([
			TweenMax.fromTo('#magic-scroll-2', 1, { paddingTop: '40%' }, { paddingTop: '0%', ease: Power3.easeOut })
		])

	if (window.matchMedia('(min-width: 1025px)').matches) {
		itemsScene = new ScrollMagic.Scene({ triggerElement: '#storytelling-bg', duration: '250%' })
			.setTween(itemsTween2)
		// .addIndicators({name: "items 2"}) // add indicators (requires plugin)
			.addTo(controller)
	} else {
		itemsScene = new ScrollMagic.Scene({ triggerElement: '#storytelling-bg', duration: '250%' })
			.setTween(itemsTween2)
		// .addIndicators({name: "items 2"}) // add indicators (requires plugin)
			.addTo(controller)
	}

	// itemsTween3 = new TimelineMax()
	// 	.add([
	// 		TweenMax.fromTo('#magic-scroll-3', 1, { paddingTop: '10%', marginBottom: '-10%' }, { paddingTop: '0%', marginBottom: '-0%', ease: Power3.easeOut })
	// 	])

	// if (window.matchMedia('(min-width: 1025px)').matches) {
	// 	itemsScene = new ScrollMagic.Scene({ triggerElement: '.link-to-pdf-section', duration: '100%' })
	// 		.setTween(itemsTween3)
	// 	// .addIndicators({name: "items 3"}) // add indicators (requires plugin)
	// 		.addTo(controller)
	// } else {
	// 	itemsScene = new ScrollMagic.Scene({ triggerElement: '.link-to-pdf-section', duration: '100%' })
	// 		.setTween(itemsTween3)
	// 	// .addIndicators({name: "items 3"}) // add indicators (requires plugin)
	// 		.addTo(controller)
	// }

	// itemsTween4 = new TimelineMax()
	// 	.add([
	// 		TweenMax.fromTo('#magic-scroll-4', 1, { transform: 'translateY(1000px)' }, { transform: 'translateY(0)', ease: Power3.easeOut })
	// 	])

	// if (window.matchMedia('(min-width: 1025px)').matches) {
	// 	itemsScene = new ScrollMagic.Scene({ triggerElement: '#pc-site-img', duration: '200%' })
	// 		.setTween(itemsTween4)
	// 	// .addIndicators({name: "items 4"}) // add indicators (requires plugin)
	// 		.addTo(controller)
	// } else {
	// 	itemsScene = new ScrollMagic.Scene({ triggerElement: '#pc-site-img', duration: '100%' })
	// 		.setTween(itemsTween4)
	// 	// .addIndicators({name: "items 4"}) // add indicators (requires plugin)
	// 		.addTo(controller)
	// }
})

// fallback: show controls if autoplay fails
// (needed for Samsung Internet for Android, as of v6.4)
// window.addEventListener('load', async () => {
//   let video = document.querySelector('video[muted][autoplay]');
//   try {
//     await video.play();
//   } catch (err) {
//     video.controls = true;
//   }
// });
