import $ from 'jquery'

function showFullNav () {
	$('.main-nav ul').children('li').addClass('active')
	setTimeout(function () {
		$('.main-nav ul').children('li').removeClass('active')
	}, 2000)
}

function popNav () {
	var windowTop = $(window).scrollTop()
	$('.navPoint').each(function () {
		var nav = $(this).data('nav')
		var top = $(this).offset().top
		if (windowTop > top && windowTop < top + 600) {
			$(nav).addClass('active')
		} else {
			$(nav).removeClass('active')
		}
	})
}

$(function () {
	$(window).scroll(popNav)
	popNav()
	showFullNav()
})

function showLogo () {
	var windowTop = $(window).scrollTop()
	var top = $('#section-marker-an-inside-look').offset().top

	if (window.matchMedia('(min-width: 768px)').matches) {
		if (windowTop >= top) {
			$('#nav-to-top').addClass('visible')
		} else {
			$('#nav-to-top').removeClass('visible')
		}
	} else {
		$('#nav-to-top').addClass('visible')
	}
}
$(function () {
	$(window).scroll(showLogo)
	showLogo()
})
